import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { insertImage, insertDocx, insertText } from './GexWord';
import { FaSpinner } from 'react-icons/fa'
import Tesseract from 'tesseract.js';

export default function PdfViewer({ url, onClose }) {
    const canvasRef = useRef();
    const canvasDessinRef = useRef();
    const canvasCaptureRef = useRef();
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const [pdfRef, setPdfRef] = useState();
    const [Loading, setLoading] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataUrl, setdataUrl] = useState(null);

    const [posX, setPosX] = useState();
    const [posY, setPosY] = useState();
    const [textOcr, settextOcr] = useState();

    const renderPage = useCallback((pageNum, pdf = pdfRef) => {
        pdf && pdf.getPage(pageNum).then(function (page) {
            const viewport = page.getViewport({ scale: zoom });
            console.log(zoom)

            const canvas = canvasRef.current;
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            canvasDessinRef.current.height = viewport.height;
            canvasDessinRef.current.width = viewport.width;
            const renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
            };
            page.render(renderContext);


            const viewport2 = page.getViewport({ scale: 10 });
            const canvas2 = canvasCaptureRef.current;
            canvas2.height = viewport2.height;
            canvas2.width = viewport2.width;
            const renderContext2 = {
                canvasContext: canvas2.getContext('2d'),
                viewport: viewport2
            };
            page.render(renderContext2);
        });
    }, [pdfRef, zoom]);

    useEffect(() => {
        renderPage(currentPage, pdfRef);
    }, [pdfRef, currentPage, renderPage]);

    useEffect(()=> {
        if(textOcr!="") {
            insertText(textOcr,"Word")
        }

    },[textOcr])

    useEffect(() => {
        const loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(loadedPdf => {
            setPdfRef(loadedPdf);
        }, function (reason) {
            console.error(reason);
        });
    }, [url]);

    const nextPage = () => pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

    const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    async function insertimage() {
        await Word.run(async (context) => {
            const range = context.document.getSelection();


            range.insertInlinePictureFromBase64(dataUrl.split(',')[1], "Start");
            await context.sync();
            setdataUrl(null);
        }).catch(function (error) {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }

    async function OCRImage() {
        setLoading(true);


        Tesseract.recognize(
            dataUrl, 'fra'
        )
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
            .then(result => {
                // Get Confidence score
                let confidence = result.confidence
                let text = result.data.text
                text = text.trim();
                if (text.substring(0, 1) == '-') {
                    text = text.substring(1, text.length)
                }
                text = text.trim();

                settextOcr(text);
   
                setLoading(false);
                setdataUrl(null);
                setPosX(0);
                setPosY(0);
            })

       



    }

    return <div className="modal fade show" style={{ display: 'block' }} >
        <div style={{ overflowY: 'auto', minWidth: '99%' }} className={"modal-dialog modal-xl"}>
            <div className="modal-content " style={{ minWidth: '99%' }}>
                <div className="p-2 modal-colored-header bg-info d-flex justify-content-between">
                    <h4>Apercu du fichier</h4>

                    <div className='d-flex align-items-center'>
                        <div>
                            <label>Zoom</label>
                            <input className='ml-3' type="range" min="0.8" defaultValue="1" max="1.8" step="0.2" onChange={(e) => setZoom(e.target.value)} />
                        </div>
                        <div>
                            <button disabled={currentPage > 1 ? '' : 'disabled'} type="button" onClick={() => prevPage()} className="ml-auto m-2 btn btn-primary" >Page précèdente</button>
                            <button disabled={pdfRef && currentPage < pdfRef.numPages ? '' : 'disabled'} onClick={() => nextPage()} type="button" className="ml-auto m-2 btn btn-primary" >Page suivante</button>
                            <button style={{ display: (dataUrl ? '' : 'none') }} onClick={() => { insertimage() }} type="button" className="ml-auto m-2 btn btn-primary" >Insérer</button>
                            <button style={{ display: (dataUrl ? '' : 'none') }} onClick={() => { OCRImage() }} type="button" className="ml-auto m-2 btn btn-primary" >OCR</button>
                        </div>
                    </div>
                    <button onClick={() => onClose()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>
                <div className="modal-body" style={{ minHeight: '80vh', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                    <div><canvas style={{ zIndex: 1, position: 'absolute' }} onMouseDown={(e) => {
                        let canvas = canvasDessinRef.current;
                        let rect = canvas.getBoundingClientRect()
                        // setPosX(e.clientX - (rect.left + window.scrollX));
                        //   setPosY(e.clientY - (rect.top + window.scrollY));
                        setPosX(e.clientX - (rect.left));
                        setPosY(e.clientY - (rect.top));

                    }}
                        onMouseMove={(e) => {
                            if (posX == 0 || posY == 0) return;
                            let canvas = canvasDessinRef.current;
                            let rect = canvas.getBoundingClientRect()
                            let ctx = canvas.getContext('2d');
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.strokeStyle = '#4444CC'; //Nuance de bleu
                            ctx.strokeRect(posX, posY, (e.clientX - (rect.left)) - posX, (e.clientY - (rect.top)) - posY);

                        }}

                        onMouseLeave={(e) => {
                            setPosX(0);
                            setPosY(0);
                            if (!dataUrl) {
                                let canvas = canvasDessinRef.current;
                                let ctx = canvas.getContext('2d');
                                ctx.clearRect(0, 0, canvas.width, canvas.height);
                            }

                        }}

                        onMouseUp={(e) => {
                            if (posX == 0 || posY == 0) return;
                            let canvas = canvasDessinRef.current;
                            let rect = canvas.getBoundingClientRect()
                            let ctx = canvas.getContext('2d');
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.strokeStyle = '#4444CC'; //Nuance de bleu
                            ctx.strokeRect(posX, posY, (e.clientX - (rect.left)) - posX, (e.clientY - (rect.top)) - posY);
                            //let ctrViewer=canvasRef.getContext('2d');

                            var canvasOCR = document.createElement("canvas");
                            var ctxOCR = canvasOCR.getContext("2d");
                            //    canvasOCR.width = (e.clientX - (rect.left + window.scrollX)) - posX;
                            //  canvasOCR.height = (e.clientY - (rect.top + window.scrollY)) - posY;
                            canvasOCR.width = (e.clientX - (rect.left)) - posX;
                            canvasOCR.height = (e.clientY - (rect.top)) - posY;
                            //    canvasOCR.width = canvas.width
                            //  canvasOCR.height = canvas.height

                            canvasOCR.width = ((e.clientX - (rect.left)) - posX) * 10 / zoom;
                            canvasOCR.height = ((e.clientY - (rect.top)) - posY) * 10 / zoom;

                            ctxOCR.drawImage(canvasCaptureRef.current, posX * 10 / zoom, posY * 10 / zoom, ((e.clientX - (rect.left)) - posX) * 10 / zoom, ((e.clientY - (rect.top)) - posY) * 10 / zoom, 0, 0, ((e.clientX - (rect.left)) - posX) * 10 / zoom, ((e.clientY - (rect.top)) - posY) * 10 / zoom);
                            //ctxOCR.drawImage(canvasRef.current, posX, posY, (e.clientX - (rect.left )) - posX, (e.clientY - (rect.top )) - posY, 0, 0, (e.clientX - (rect.left )) - posX, (e.clientY - (rect.top )) - posY);
                            //ctxOCR.drawImage(canvasRef.current, posX, posY, (e.clientX - (rect.left + window.scrollX)) - posX, (e.clientY - (rect.top + window.scrollY)) - posY, 0, 0, (e.clientX - (rect.left + window.scrollX)) - posX, (e.clientY - (rect.top + window.scrollY)) - posY);


                            setdataUrl(canvasOCR.toDataURL("image/jpeg"));



                            setPosX(0);
                            setPosY(0);
                            //ctx.strokeRect(100, 100, 200, 200);

                        }}
                        id='canvas' className='canvasDessin' ref={canvasDessinRef}></canvas>
                        <canvas style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', zIndex: 0 }} id='canvas' className='canvasViewer' ref={canvasRef}></canvas>
                        <canvas style={{ marginLeft: 'auto', marginRight: 'auto', display: 'none', zIndex: 0 }} id='canvas' className='canvasHR' ref={canvasCaptureRef}></canvas></div>
                </div>

            </div>


        </div>

    </div >


}